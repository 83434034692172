ul.breadcrumbs {
  padding: 0;
  margin: 0;

  font-size: $text-base;

  li {
    display: inline-block;
    color: $neutral;

    &:after {
      content: '/';
      display: inline-block;
      margin: 0 0.5em;
      color: $neutral-60;
    }

    &:last-child {
      color: $neutral-60;

      &:after {
        display: none;
      }
    }

    a {
      border: none;
      color: inherit;

      &:hover {
        color: $primary-lighter;
      }
    }
  }
}
