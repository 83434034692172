section.features-alternating {
  .container {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .feature {
    margin-bottom: 80px;

    &:nth-child(2n) {
      .image {
        order: 1;
      }

      .content {
        order: 2;
      }
    }

    &:nth-child(2n+1) {
      .image {
        order: 2;
      }

      .content {
        order: 1;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include respond-to('tablet') {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .image, .content {
        width: calc(50% - 20px);
        flex: 1;
      }

      .content {
        margin: 40px;
      }
    }
  }

  .image {
    margin-bottom: 40px;

    img {
      display: block;
      max-width: 100%;
    }
  }

  .tagline {
    @extend h6;

    padding-bottom: 15px;
    border-bottom: solid 1px $neutral-15;
    margin-bottom: 30px !important;
  }

  h3 {
    margin-bottom: 10px !important;
  }

  h2 {
    margin-bottom: 80px;
    text-align: center;
  }

  .description {
    color: $neutral-80;
  }
}
