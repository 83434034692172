@use 'sass:math';

@function rem($size) {
  @return math.div($size, $base-size) * 1rem;
}

$breakpoints: (
  'mobile':  (max-width:  768px),
  'tablet': (min-width: 768px),
  'desktop':  (min-width: 1024px),
  'large-desktop': (min-width: 1200px)
) !default;

$base-size: 14px;

$text-sm: rem(12px);
$text-little: rem(13px);
$text-base: rem(14px);
$text-lg: rem(16px);
$text-xl: rem(18px);
$text-2xl: rem(22px);
$text-3xl: rem(26px);
$text-4xl: rem(32px);
$text-5xl: rem(42px);
$text-6xl: rem(54px);
$text-7xl: rem(60px);

$font-primary: 'Roboto', sans-serif;
$font-secondary: 'DM Mono', sans-serif;

$primary: #3793ff;
$primary-lighter: #4b9eff;
$primary-pastel: #e8f2ff;

$orange: #ff8f00;
$orange-pastel: #fff3e5;

$neutral: #000000;
$neutral-90: #585858;
$neutral-80: #505050;
$neutral-60: #848484;
$neutral-40: #B5B5B5;
$neutral-15: #e5e5e5;
$neutral-10: #F4F4F4;
$neutral-5: #F9F9F9;

$white: #ffffff;
$gray-on-black: #808080;

$red: #ff3900;
$red-darker: #ab2039;
$red-lighter: #f83c5d;
$red-pastel: #f83c5d;

$green: #00d281;
$green-darker: #278060;
$green-lighter: #01dc88;
$green-pastel: #e5faf2;

$light-blue: #d2e4f1;
