section.customers {
  .customer {
    .container {
      padding-top: 100px;
      padding-bottom: 120px;
    }

    &:nth-child(2n+1) {
      background-color: $neutral-5;
    }

    @include respond-to('tablet') {
      .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .meta {
        width: calc(33.3333% - 30px);
      }

      .content {
        width: calc(66.6667% - 30px);
      }
    }
  }

  .image {
    box-shadow: 2px 4px 40px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    border-radius: 4px;

    img {
      display: block;
      width: 100%;
    }
  }

  .company {
    margin-bottom: 40px;

    header {
      @extend h2;

      font-size: $text-4xl !important;
      margin-bottom: 20px !important;
    }

    .description {
      margin-bottom: 10px;
    }
  }

  .founder {
    margin-bottom: 40px;

    header {
      @extend h4;
    }

    .avatar {
      margin-bottom: 20px;
    }
  }

  .role {
    color: $neutral-60;
    margin-top: 4px;
  }

  .content {
    blockquote {
      font-size: $text-lg;
      margin: 0 0 60px;
    }
  }
}
