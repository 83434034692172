input {
  appearance: none;

  border: 1px solid $neutral-15;
  box-sizing: border-box;
  border-radius: 4px;

  display: block;

  font-size: $text-xl;
  line-height: 1.3;
  padding: 15px 15px 13px;

  color: $neutral;

  &::placeholder {
    color: $neutral-40;
  }

  &:focus {
    outline: none;
    border: solid 1px $primary;
  }
}
