section.call-to-action {
  text-align: center;

  .container {
    padding-top: 110px;
    padding-bottom: 120px;
  }

  .content {
    .icon {
      color: white;
      width: 80px;
      height: 80px;
      margin-bottom: 20px;
    }

    h2 {
      max-width: 900px;
      margin: 0 auto 20px;
    }
  }

  .description {
    max-width: 640px;
    margin: 20px auto 60px;
    font-size: $text-lg;
    color: $neutral-60;
    line-height: 1.7;
  }

  form {
    max-width: 756px;
    margin-left: auto;
    margin-right: auto;
  }

  .caption {
    font-size: $text-base;
    margin: 45px auto 0;
    max-width: 640px;

    a {
      color: inherit;

      &:hover {
        color: $neutral-60;
      }
    }
  }
}
