section.video-message {
  .container {
    max-width: 720px;
    text-align: center;

    padding-top: 100px;
    padding-bottom: 120px;
  }

  .tagline {
    margin-top: 20px;
  }

  .video {
    box-shadow: 2px 4px 40px rgba(0, 0, 0, 0.15);
    margin-top: 60px;
  }

  .description {
    margin: 60px auto 0;
    max-width: 600px;

    font-size: $text-lg;
    text-align: left;
  }
}
