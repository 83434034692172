section.gallery {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 100px;
    padding-bottom: 120px;
  }

  h2 {
    text-align: center;
    margin-bottom: 80px;
  }

  .images {
    margin: -10px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    white-space: nowrap;

    overflow: auto;

    .image {
      margin: 10px;
      max-width: calc(33.3333% - 20px);
      flex-shrink: 0;

      img {
        display: block;
        width: 100%;
      }
    }
  }
}
