.sidebar {
  max-width: 300px;
  flex: 1;
  padding-top: 20px;
  padding-bottom: 40px;

  nav {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      color: $neutral;
      font-size: $text-lg;

      a {
        border: none;
        color: inherit;

        .icon {
          font-size: $text-xl;
          color: $neutral;
          margin-right: 15px;
        }

        .icon-angle-down {
          color: inherit;
          margin-left: 5px;

          transition-property: transform, opacity;
          transition-duration: .1s;
          transition-timing-function: ease-in-out;
          transform-origin: center, opacity;
          transform: rotate(-90deg);
          opacity: 0;

          will-change: transform;
        }

        &[aria-expanded=true] {
          .icon-angle-down {
            opacity: 1;
            transform: rotate(0);
          }
        }
      }

      li {
        padding: 0 0 15px 0;

        > a {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
        }
      }

      ul {
        flex-basis: 100%;
        font-size: $text-base;
        color: $neutral-80;
        margin-top: 15px;
        padding-left: calc(#{$text-xl} + 15px);
        display: none;

        &.visible {
          display: block;
        }

        .icon {
          display: none;
        }

        li {
          line-height: 1.5;
          padding-bottom: 10px;
        }

        a {
          &[aria-current=page] {
            font-weight: 600;
            color: $neutral;
          }

          &:hover {
            color: $primary-lighter;
          }
        }


        ul {
          padding-left: 0;

          li {
            padding-block: 5px;
            padding-left: $text-xl;
            border-left: solid 1px $neutral-15;
          }

          li.current {
            border-left: solid 1px black;
            color: black;
          }
        }
      }
    }
  }

  .search {
    margin-bottom: 40px;

    .results {
      position: absolute;
      z-index: 10000;
      top: 40px;

      border-radius: 5px;
      box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.1);

      padding: 20px;

      width: 800px;
      max-width: calc(100vw - 12%);

      overflow-y: auto;
      max-height: 500px;
    }
  }
}
