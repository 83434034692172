section.founders {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    padding-top: 100px;
    padding-bottom: 120px;
  }

  h2 {
    margin-bottom: 20px;
  }

  .description {
    font-size: $text-xl;
    font-weight: normal;

    margin: 0 0 60px;

    max-width: 640px;
  }

  .avatar {
    width: 80px;
    height: 80px;
  }

  .founders {
    display: grid;
    grid-gap: 40px 30px;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    align-items: start;

    margin-top: 60px;
    width: 100%;
    max-width: 720px;

    .founder {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    header {
      @extend h4;

      font-size: $text-base !important;
      margin: 20px 0 0px !important;
      font-weight: normal;
    }

    .company {
      color: $neutral-60;
      font-size: $text-little;
    }
  }
}
