section.pricing {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 100px;
    padding-bottom: 120px;
  }

  .hero + & .container {
    padding-top: 0;
  }

  .tagline {
    max-width: 640px;
    margin: 20px auto 70px;
    font-size: $text-xl;
    line-height: 1.7;
  }

  .pricing-switcher {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    font-size: $text-base;
    margin-top: 40px;

    a {
      display: block;
      color: $neutral-60;
      padding: 8px 20px;
      transition: none;
      border: none;

      + a {
        border-left-color: transparent;
      }

      &:first-child {
        border-radius: 20px 0 0 20px;
      }

      &:last-child {
        border-radius: 0 20px 20px 0;
      }

      &.active {
        background-color: $neutral-5;
        color: $neutral;
      }
    }
  }

  .plans {
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    margin: 70px 0 50px;
    width: 100%;
    max-width: 1100px;

    @include respond-to('desktop') {
      flex-direction: row;
      justify-content: space-around;
    }
  }

  .plan {
    position: relative;
    background-color: $neutral-5;
    box-sizing: border-box;
    border-radius: 4px;

    margin: 20px 0;
    padding: 50px;
    z-index: 10;
    max-width: 100%;
    flex: 1;

    &:nth-child(2) {
      background-color: white;
      z-index: 100;

      box-shadow: 2px 4px 40px rgba(0, 0, 0, 0.15);
    }

    header {
      font-size: $text-4xl;
      line-height: 1.3;
      letter-spacing: -0.01em;
      color: $neutral;

      display: flex;
      flex-direction: column;

      .icon {
        font-size: 50px;
        line-height: 1.2;
        margin-bottom: 0.5em;
      }
    }

    .description {
      color: $neutral-80;
      line-height: 1.5;
      margin: 15px 0;
    }

    .price {
      margin: 30px 0;
      color: $neutral;
      font-size: $text-2xl;
      font-weight: 300;

      .interval,
      .label {
        color: $neutral-60;
      }
    }

    .features {
      margin: 40px 0;

      display: flex;
      flex-direction: column;
      color: $neutral-80;
      line-height: 1.5;
      gap: 5px;

      .item {
        display: flex;
        flex-direction: row;

        .icon {
          margin-right: 10px;
          color: $neutral-40;
        }

        .description {
          margin: 0;
        }
      }
    }

    .actions {
      margin-top: 30px;

      .button {
        font-size: $text-little;
        padding: 1em 2em;

        .icon {
          font-size: 1.2em;
        }
      }
    }

    @include respond-to('desktop') {
      margin: 0;
      border-radius: 0;

      width: 255px;
      flex-grow: 1;
      flex-shrink: 0;

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }

      &:nth-child(2) {
        margin: -20px 0;
        padding: 70px 50px;
        z-index: 100;
        border-radius: 4px;
      }
    }
  }

  .slider {
    width: 100%;
    max-width: 790px;
    margin: 20px auto;
    color: $neutral-80;
    font-size: $text-lg;

    span {
      color: $neutral;
      font-size: $text-5xl;
      font-weight: 300;
    }

    .preview {
      max-width: 300px;
      text-align: center;
      margin: 0 auto;

      @include respond-to('tablet') {
        max-width: none;
      }
    }

    input[type=range] {
      border: none;
      width: 100%;
      margin: 40px 0;
      padding: 0;

      @mixin range-track {
        box-sizing: border-box;
        border: none;
        background-color: $neutral-15;
        width: calc(100% - 34px);
        height: 6px;
        border-radius: 3px;
      }

      @mixin range-progress {
        box-sizing: border-box;
        border: none;
        background-color: $primary;
        width: calc(100% - 34px);
        height: 6px;
        border-radius: 3px;
      }

      @mixin range-thumb {
        appearance: none;
        box-sizing: border-box;
        box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.1);
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border: solid 12px $primary;
        background-color: white;
        margin: -17px 0;
      }

      // Track
      &::-webkit-slider-runnable-track { @include range-track; }
      &::-moz-range-track { @include range-track; }
      &::-ms-track { @include range-track; }

      // Progress
      &::-moz-range-progress { @include range-progress; }
      &::-ms-fill-lower { @include range-progress; }

      // Thumb
      &::-webkit-slider-thumb { @include range-thumb; }
      &::-moz-range-thumb { @include range-thumb; }
      &::-ms-thumb { @include range-thumb; }
    }
  }


  .features {
    list-style: none;
    padding: 0;
    margin: 20px auto 40px;

    max-width: 320px;
    width: 100%;

    .feature {
      display: flex;
      align-items: flex-start;
      margin: 15px 0;

      .icon {
        color: $neutral-40;
        text-align: center;
        margin-top: -3px;
        margin-right: 8px;
        font-size: 18px;
        width: 30px;
        flex: 0 1 auto;
      }

      .description {
        color: $neutral-80;
        margin: 0;
        max-width: auto;
      }
    }
  }

  .container > .description {
    margin: 30px 0 60px;

    max-width: 460px;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .actions {
    margin: -10px -5px;

    > * {
      margin: 10px 5px;
    }
  }
}
