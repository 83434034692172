form {
  display: flex;
  flex-direction: column;

  margin: -10px;

  > button,
  > input {
    margin: 10px !important;
  }

  input {
    flex: 1;
  }

  @include respond-to('tablet') {
    flex-direction: row;
  }
}
