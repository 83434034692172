section.resource {
  margin-top: 60px;
  border-bottom: solid 1px $neutral-15;

  &:last-child {
    border-bottom: none;
  }

  h2, h3 {
    margin: 20px 0;
  }

  h4 {
    margin-bottom: 10px;
  }

  .request {
    margin-bottom: 60px;
  }

  .request-request {
    margin: 0;
    border: solid 1px $neutral-15;
    padding: 1em;
    width: 100%;
    box-sizing: border-box;

    .request-method {
      &[data-method="POST"] {
        color: $green-darker;
      }

      &[data-method="DELETE"] {
        color: $red-darker;
      }
    }
  }

  .request-properties {
    list-style: none;
    margin: 0;
    padding: 0;

    .request-property {
      padding: 15px 0;
      border-bottom: 1px solid $neutral-15;

      &:first-child {
        border-top: 1px solid $neutral-15;
      }

      h5 {
        margin: 0 0 15px 0;
        font-size: $text-base;
        text-transform: none;

        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;

        .name {
          font-family: $font-secondary;
        }

        .types, .required, .optional {
          font-size: $text-sm;
        }

        .types {
          margin-left: auto;
        }

        .type {
          color: $neutral-40;
          border: solid 1px $neutral-15;
          border-radius: 3px;
          padding: 0px 5px;
        }

        .required {
          color: $red-darker;
          font-weight: bold;
        }

        .optional {
          color: $neutral-40;
          font-weight: normal;
        }
      }
    }

    .description {
      font-size: $text-base;
    }
  }

  .response {
    table {
      margin: 0;
    }
  }
}