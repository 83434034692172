.badge {
  display: inline-block;
  background: $neutral-10;
  padding: 4px 8px;
  margin-left: 4px;
  margin-right: 4px;
  border: solid 0px $neutral-40;
  border-radius: 4px;
  color: $neutral;
  font-weight: normal;
  font-style: normal;
  font-size: $text-sm;
  font-family: $font-secondary;
  line-height: 1em;
  vertical-align: middle;
  text-transform: lowercase;
  text-decoration: none;

  &:hover {
    color: $neutral;
  }

  &:is(a) {
    &:hover {
      border-color: $primary;
      color: $primary;
    }
  }
}