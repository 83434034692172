section.comparison-table {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    padding-top: 100px;
    padding-bottom: 120px;
  }

  .scroll-container {
    max-width: 100%;
    overflow: auto;
  }

  h2 {
    margin-bottom: 20px;
  }

  .description {
    max-width: 720px;
    font-size: $text-lg;
  }

  .actions {
    margin-top: 60px;
    flex-wrap: wrap;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    max-width: 720px;
    margin: 60px 0;

    td, th {
      border-bottom: solid 1px $neutral-15;
      padding: 10px 20px;

      &:first-child {
        text-align: left;
        position: sticky;
        left: 0px;
        z-index: 2;
        background: white;
      }
    }

    th {
      font-weight: normal;
      color: $neutral-60;
    }

    .icon {
      font-size: $text-xl;

      &.icon-circle-check {
        color: $green;
      }

      &.icon-horizontal-rule {
        color: $neutral-40;
      }
    }
  }
}
