body > footer {
  border-top: solid 1px $neutral-15;
  background-color: white;
  color: $neutral;

  .container {
    @include grid(4);
    padding: 60px 6% 120px;
  }

  a {
    color: $neutral-80;
  }

  header {
    @extend h6;
  }

  ul {
    list-style: none;
    margin: 20px 0 30px;
    padding: 0;

    li {
      margin: 4px 0;
      line-height: 1.5;
    }

    &.icons li {
      display: inline;
      margin: 5px 1em 5px 0;
      font-size: $text-xl;
      color: $neutral;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .tagline {
    font-size: $text-base;
    color: $neutral-60;
  }

  address {
    font-style: normal;
    margin: 20px 0 30px;
  }
}
