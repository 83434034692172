.code {
  font-size: 0.9em;
  color: #faf6e4;
  background-color: #122b3b;
  position: relative;
  width: 100%;
  border-radius: 4px;
  margin: 20px 0 40px 0;
  max-width: 100%;
  overflow-x: auto;
  -webkit-text-size-adjust: 100%;
  font-size: 1rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  header {
    background-color: #2c414f;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding: .6em 1em;

    .method, .path {
      font-family: $font-secondary;
    }

    .method[data-method="POST"] {
      color: $green;
    }

    .method[data-method="DELETE"] {
      color: $red;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 5px;
    margin-left: auto;
    min-height: fit-content;
    position: absolute;
    right: 0;
    top: 0;
    padding: .5em;

    select, button {
      height: 25px;

      appearance: none;
      background-color: #2c414f;
      border: none;
      border-radius: 3px;
      padding: 0.5em 1em;
      color: $neutral-40;
    }

    select {
      padding-right: 2em;

      background-image: svg-load('light/chevron-down.svg', fill=$neutral-40);
      background-size: 10px auto;
      background-position: right 8px center;
      background-repeat: no-repeat;
    }

    button {
      svg {
        width: 1em;
        height: 1em;
      }

      svg:nth-child(2) {
        display: none;
      }

      &.success {
        color: $green;

        svg:nth-child(1) {
          display: none;
        }

        svg:nth-child(2) {
          display: block;
        }
      }
    }
  }

  &:has(header) .actions {
    padding: 0.5em 1em;

    select, button {
      background-color: #122b3b;
    }
  }

  .code-variant[aria-hidden=true] {
    display: none;
  }

  .highlight {
    margin: 0 !important;

    code:not(:has(table)) {
      padding: 5px;
    }
  }
}
