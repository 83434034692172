section.service {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: column;
    text-align: center;

    padding-top: 100px;
    padding-bottom: 120px;
  }

  h1 {
    max-width: 550px;
    font-size: $text-4xl;
  }

  .description {
    font-size: $text-xl;
    font-weight: normal;

    margin: 30px 0 60px;

    max-width: 550px;

    &:last-child {
      margin-bottom: 0;
    }

    blockquote {
      padding: 35px;
      background-color: $neutral-5;
      border-radius: 4px;
      font-size: $text-base;
      max-width: 800px;
      margin: 40px 0 0 0;
      letter-spacing: -0.01em;
    }
  }

  .actions {
    margin: -10px -5px;

    > * {
      margin: 10px 5px;
    }
  }
}
