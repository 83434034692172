body.minimal {
  > header nav {
    color: $white;
    background-color: black;
  }

  section.hero {
    background: black;

    .container {
      padding-bottom: clamp(350px, 20vw, 400px);
    }

    color: $gray-on-black;

    h1 {
      color: white;
    }
  }

  section.image {
    background: transparent;
    margin-top: calc(clamp(-250px, -20vw, -150px));
  }

  > footer {
    border-top: none;

    .container {
      display: block;
      padding-top: 20px;
      padding-bottom: 80px;
    }

    ul.centered {
      text-align: center;
      margin: 0 auto;

      li {
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;

        a {
          text-decoration: underline;
          color: $neutral-60;
        }
      }
    }
 }
}
