section.podcasts {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 100px;
    padding-bottom: 120px;
  }

  h2 {
    text-align: center;
    margin-bottom: 80px;
  }

  .podcasts {
    @include grid(3, 100px 80px);
  }

  .podcast {
    text-align: center;

    header {
      @extend h4;

      margin: 30px 0 20px !important;
    }
  }

  .image {
    max-width: 100%;
    box-shadow: 2px 4px 40px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    border-radius: 4px;

    img {
      display: block;
      width: 100%;
    }

    a {
      text-decoration: none;
    }
  }

  .slots {
    color: $neutral-60;
  }
}
