.cookie-consent {
  position: fixed;
  z-index: 1000;

  bottom: 0;
  left: 0;
  right: 0;

  font-size: $text-sm;

  background-color: white;
  box-shadow: 2px 4px 32px rgba(black, 0.2);

  display: flex;
  flex-direction: row;
  align-items: stretch;

  overflow: hidden;

  transition-property: transform, opacity;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;

  will-change: transform, opacity;

  transform: translate(0, 10px);
  opacity: 0;
  pointer-events: none;

  &.visible {
    opacity: 1;
    transform: translate(0, 0);
    pointer-events: auto;
  }

  .message {
    padding: 1rem 1rem 1rem 2rem;
    align-self: center;
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    flex-grow: 1;
  }

  .button {
    font-size: inherit;
    flex: 1;

    border-color: $neutral-15;
    border-style: solid;
    border-width: 0 0 0 1px;
    border-radius: 0;
    box-shadow: none;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1rem 1.42rem;

    &:hover {
      background-color: $neutral-5;
    }

    &:first-child {
      border-bottom-width: 1px;
    }
  }

  @include respond-to('tablet') {
    border-radius: 4px;

    bottom: 2rem;
    left: 2rem;
    right: auto;
    width: 450px;
    max-width: calc(100vw - 4rem);
  }
}

template {
  display: none;
}
