section.divider {
  .container {
    position: relative;
    max-width: 150px;
    height: 0;
    margin: -1px auto;
    border: solid 1px $primary;
    z-index: 10;
    padding: 0;
  }
}
