div.lead-magnet {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px auto;
  min-height: 280px;

  background-color: black;
  background-repeat: no-repeat;
  background-size: auto 280px;
  padding: 180px 25px 50px;
  border-radius: 20px;
  box-sizing: border-box;

  background-position: 50% -150px;

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  header {
    font-size: $text-2xl;
    line-height: 1.3;
    letter-spacing: -0.01em;
  }

  .description {
    font-size: $text-base;
  }

  form {
    flex-direction: row;

    input {
      padding: 10px 0;

      background-color: transparent;

      border-radius: 0;
      border: none;
      border-bottom: solid 1px;

      font-size: $text-base;
    }

    button {
      padding: 13px 25px;
      font-size: $text-base;
    }
  }

  @media (min-width: 600px) {
    max-width: 640px;

    background-position: calc(50% - 280px) 50%;
    padding: 70px 50px 70px 220px;

    .content {
      max-width: 340px;
    }
  }

  &.atoms {
    background-image: url('/images/atoms.webp');
  }

  &.donut {
    background-image: url('/images/donut.webp');
  }

  &.cronut {
    background-image: url('/images/cronut.webp');
  }

  &.nautilus {
    background-image: url('/images/nautilus.webp');
  }

  &.dark {
    background-color: black;
    color: white;

    form {
      input {
        border-color: white;
        color: white;
      }
    }
  }

  &.light {
    background-color: $light-blue;
    color: black;

    form {
      input {
        color: black;
        border-color: rgba(0, 0, 0, 0.4);

        &::placeholder {
          color: rgba(0, 0, 0, 0.4);
        }
      }

      button {
        background-color: black;
        color: white;

        &:hover {
          background-color: rgba(0, 0, 0, 0.9);
        }
      }
    }
  }
}