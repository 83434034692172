section.article {
  .container {
    display: block;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    max-width: none;

    padding: 60px clamp(5px, 3%, 6%) 70px;
  }

  .container > header {
    max-width: 820px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    width: 100%;
    margin: 80px auto 40px;

    h1, .description {
      text-align: center;
    }

    h1 {
      margin: 0;
      line-height: 1.2;

      @include respond-to('tablet') {
        font-size: rem(50px);
      }
    }

    .description {
      font-size: $text-xl;
      line-height: 1.5;
      color: $neutral-80;
      max-width: 620px;
      margin: 0 auto;
    }

    .cover {
      margin-top: 20px;
      max-width: 820px;

      img {
        max-width: 100%;
      }
    }

    .meta {
      color: $neutral-60;
      font-family: $font-secondary;
      font-size: $text-base;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 30px;

      .author {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .avatar {
          width: 40px;
          height: 40px;
        }
      }

      .date {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .icon {
          width: 16px;
          height: 16px;
        }
      }

      .tags {
        display: flex;
        flex-direction: row;
        gap: 5px;

        .tag {
          font-size: $text-sm;
          background-color: $neutral-5;
          border-radius: 4px;
          border: none;
          padding: 4px 8px;
          color: $neutral;
          text-decoration: none;
        }
      }
    }
  }

  article {
    width: 100%;
    margin: 0 auto 40px;

    @include respond-to('desktop') {
      max-width: 620px;
      display: block;
    }
  }

  .table-of-contents {
    position: sticky;
    display: none;
    top: 110px;
    left: 20px;
    max-width: 220px;
    float: left;

    @include respond-to('large-desktop') {
      display: block;
    }

    header {
      font-family: $font-secondary;
      color: $neutral-60;

      background-image: svg-load('light/bars.svg', fill=$neutral-60);
      background-repeat: no-repeat;
      background-size: 18px 18px;
      background-position: center left;

      margin-bottom: 30px;
      padding-left: 30px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      background-image: svg-load('light/circle.svg', fill=$neutral-15);
      background-repeat: no-repeat;
      background-size: 18px 18px;
      background-position: 0 2px;
      padding-left: 30px;
      padding-bottom: 10px;
    }

    a {
      color: $neutral;
      border: none;
    }

    li.viewed,
    li.visible {
      background-image: svg-load('light/circle-check.svg', fill=$primary);
    }
  }

  section.inline {
    max-width: 650px;
    margin: 0 auto 40px;

    padding: 0  0 60px 0;
    border-top: solid 1px $neutral-15;

    header {
      font-size: rem(28px);
      font-weight: 300;
      line-height: 1.25;
      margin-top: 40px;
      color: $neutral;

      margin-bottom: 20px !important;
    }

    .description {
      margin-bottom: 30px;
    }
  }

  .about-author,
  .share {
    display: flex;
    flex-direction: column;
    gap: 30px;

    width: 620px;
    max-width: 100%;
    margin: 0 auto 40px;

    header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      font-size: $text-base;
      font-family: $font-secondary;
      color: $neutral-60;

      .icon {
        height: 18px;
        width: 18px;
      }
    }
  }

  .about-author {
    .avatar {
      width: 60px;
      height: 60px;
    }

    .author {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      flex-grow: 0;
      flex-shrink: 0;

      gap: 20px;
    }

    p {
      color: $neutral;
      line-height: 1.5;
      flex: 1;
      margin: 0;
    }
  }

  .share {
    .links {
      display: flex;
      flex-direction: row;
      gap: 20px;

      a {
        text-decoration: none;
        color: $neutral-60;
        border: none;
      }

      .icon {
        height: 20px;
        width: 20px;
      }
    }
  }
}
