section.team {
  .container {
    padding-bottom: 120px;
  }

  .avatar {
    width: 120px;
    height: 120px;
    margin-bottom: 35px;
  }

  .members {
    @include grid(3, 80px);
  }

  .member {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    header {
      @extend h3;
    }

    .description {
      margin: 25px 0;
    }

    .role {
      color: $neutral-60;
    }

    .social a {
      color: $neutral;
      border: none;
      margin: 5px;

      &:hover {
        color: $primary-lighter;
      }
    }

    .tags {
      margin-bottom: 20px;
      margin-top: -10px;

      .tag {
        display: inline-block;
        background: $neutral-10;
        padding: 4px 8px;
        border: solid 0px $neutral-40;
        border-radius: 4px;
        color: $neutral;
        font-weight: normal;
        font-style: normal;
        font-size: $text-sm;
        font-family: $font-secondary;
        line-height: 1em;
        vertical-align: middle;
        text-transform: lowercase;
        text-decoration: none;
      }
    }
  }
}
