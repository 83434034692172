section.features-square {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 100px;
    padding-bottom: 120px;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
    max-width: 900px;
  }

  .tagline {
    text-align: center;
    font-size: $text-xl;
    font-weight: normal;
    margin: 0 0 80px;
    max-width: 640px;
  }

  .features {
    @include grid(4, 10px);
  }

  &.two-columns {
    .features {
      @include grid(2, 10px);
    }
  }

  &.three-columns {
    .features {
      @include grid(3, 10px);
    }
  }

  &.four-columns {
    .features {
      @include grid(4, 10px);
    }
  }

  .feature {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: $neutral-5;
    border-radius: 20px;
    padding: 40px;

    header {
      @extend h4;
      margin-bottom: 10px !important;
    }

    .icon {
      font-size: 1.8rem;
      color: $neutral;
      width: 40px;
      text-align: left;

      .icon {
        vertical-align: top;
      }
    }

    .content {
      flex: 1;
    }

    .description {
      color: $neutral-80;
    }
    
  }

  .icon {
    color: $neutral-40;
  }

}
