section.checklist {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 100px;
    padding-bottom: 120px;
  }

  h2 {
    text-align: center;
    margin-bottom: 80px;
  }

  .checklist {
    @include grid(2, 20px 40px);

    max-width: 720px;
    width: 100%;
  }

  &.three-columns {
    .checklist {
      max-width: 100%;
      @include grid(3, 20px 40px);
    }
  }

  .item {
    display: flex;
    align-items: flex-start;

    .icon {
      color: $neutral-40;
      text-align: center;
      margin-right: 8px;
      margin-top: -3px;
      font-size: 18px;
      width: 30px;
      flex: 0 1 auto;
    }

    .description {
      color: $neutral-80;
    }
  }
}
