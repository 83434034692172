.viewport-transition {
  transition: .5s ease-in-out;

  &.viewport-visible {
    outline: solid red 5px;
  }

  &.fade-in {
    transition-property: opacity;
    opacity: 0;

    &.viewport-entered {
      opacity: 1;
    }
  }

  &.fade-in-up {
    transition-property: opacity, transform;

    opacity: 0;
    transform: translateY(40px);

    &.viewport-entered {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
