section.documentation {
  .sidebar {
    display: none;

    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;

    height: fit-content;

    nav {
      height: fit-content;
      max-height: calc(100dvh - 194px);
      overflow-y: auto;
    }

    .brand {
      margin: 6px 0 46px -11px;

      a {
        padding: 11px;
        border: none;

        color: black;

        &:hover {
          color: black;
        }
      }
    }
  }

  article {
    padding-bottom: 120px;
    max-width: 650px;

    .breadcrumbs {
      margin-bottom: 45px;
    }

    h1 {
      margin-bottom: 40px;
      font-size: rem(44px);
    }

    h1, h2, h3, h4, h5, h6 {
      position: relative;

      .anchor-button {
        position: absolute;
        top: 50%;
        left: calc(max(-20px, -1em) - 10px);
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        padding-right: 10px;

        color: $neutral-40;

        will-change: opacity;

        svg {
          width: min(1em, 20px);
          aspect-ratio: 1;
        }
      }

      &:hover .anchor-button {
        opacity: 1;
        color: $neutral-40;
      }
    }

    footer {
      border-top: solid 1px $neutral-15;
      padding: 20px 0;
      margin-top: 45px;
      font-size: $text-base;

      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .icon {
        color: $neutral-40;
        font-size: $text-xl;
        margin-right: 0.5em;
        margin-top: 0.1em;
      }
    }

    body.docs_index & {
      .breadcrumbs {
        visibility: hidden;
        margin-bottom: 0px;
      }

      footer {
        display: none;
      }
    }
  }

  @include respond-to('desktop') {
    .container {
      display: grid;
      grid-template-columns: auto 6% 250px 40px 80px minmax(0, 650px) minmax(6%, 80px) auto;
      // grid-template-columns: 0 40px 250px 40px 80px auto 80px 0;
      grid-template-areas: "gutter-left sidebar-gutter-left sidebar sidebar-gutter-right article-gutter-left article article-gutter-right gutter-right";

      max-width: none;
      padding: 0;

      &::before {
        content: "";
        grid-column-start: gutter-left;
        grid-column-end: sidebar-gutter-right;
        grid-row: 1;
        background-color: $neutral-5;
      }
    }

    .sidebar {
      display: block;
      grid-area: sidebar;
    }

    article {
      grid-area: article;

      padding-block: 15px 120px;
    }
  }
}
