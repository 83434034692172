div.video {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  border-radius: 4px;
  z-index: 1;
  box-shadow: 2px 4px 40px rgba(0, 0, 0, 0.15);
  margin: 20px 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}
