section.landing-page-hero {
  .container {
    padding-top: 300px;
    padding-bottom: 120px;

    background-position: top center;
    background-repeat: no-repeat;
    background-size: auto 300px;

    @include respond-to('tablet') {
      background-size: auto 500px;
      padding-top: 500px;
    }

    @include respond-to('desktop') {
      padding-top: 100px;

      background-position: center right;
      background-repeat: no-repeat;
      background-size: 600px;
    }
  }

  .brand {
    margin-bottom: 30px;

    .logo {
      width: 140px;
      height: 41.51px;
    }

    a {
      color: inherit;
      border: none;
    }

    @include respond-to('desktop') {
      .logo {
        width: 160px;
        height: 47.44px;
      }
    }
  }

  .content {
    align-items: flex-start;
    text-align: left;
    max-width: 650px;
  }

  .description {
    font-size: $text-xl;
    font-weight: normal;

    margin: 30px 0 60px;
    max-width: 530px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .caption {
    color: rgba($neutral-80, 0.6);
    margin-top: 45px;
    max-width: 530px;

    a {
      color: inherit;

      &:hover {
        color: rgba($neutral-80, 0.8);
      }
    }
  }

  .actions {
    margin: -10px -5px;

    > * {
      margin: 10px 5px;
    }
  }

  h1 {
    @include respond-to('mobile') {
      font-size: rem(40px);
    }

    @include respond-to('tablet') {
      font-size: rem(50px);
    }
  }

  &.dark {
    .brand {
      color: #938ba2;
    }

    .caption {
      color: $neutral-80;

      a:hover {
        color: $neutral-60;
      }
    }
  }

  &.custom {
    background-color: var(--background-color);

    h1 {
      color: var(--title-color);
    }

    .brand {
      color: var(--brand-color);
    }

    .description {
      color: var(--description-color);
    }

    .caption {
      color: var(--caption-color);
    }
  }
}
