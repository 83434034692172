section.more-articles {
  border-top: solid 1px $neutral-15;

  .container {
    padding-top: 100px;
    padding-bottom: 120px;
  }

  .articles {
    @include grid(3, 60px);

    padding: 60px 0;

    h4 {
      margin-bottom: 10px;
    }

    img {
      max-width: 100%;
      display: block;
      margin-bottom: 30px;
    }
  }

  time {
    display: inline-block;
    margin-bottom: 10px;

    color: $neutral-60;
  }
}
