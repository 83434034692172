section.screenshots {
  padding: 50px 0;

  .container {
    padding-top: 100px;
    padding-bottom: 100px;


    @include respond-to('desktop') {
      background-image: url('/images/dot-pattern.svg');
      background-position: top center;
      background-repeat: repeat-y;
      background-attachment: fixed;
    }
  }

  .screenshot {
    margin: 0 0 150px 0;

    &:nth-child(2n) {
      margin-left: auto;
    }

    &:nth-child(2n+1) {
      margin-right: auto;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include respond-to('desktop') {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: 970px;

      .content {
        margin-left: 50px;
        flex-shrink: 0;
        max-width: 210px;
      }

      .image {
      }
    }
  }

  .image {
    box-shadow: 2px 4px 40px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    border-radius: 4px;
    width: 720px;
    max-width: 100%;

    img {
      display: block;
      width: 100%;
    }
  }

  .content {
    padding-top: 50px;
  }

  .item {
    display: flex;

    margin-bottom: 20px;

    .icon {
      color: $neutral;
      width: 40px;
      text-align: center;
      font-size: 1.145em;
    }

    .description {
      color: $neutral-80;
      margin-left: 10px;
      flex: 1;
    }
  }

  header {
    @extend h3;

    margin-bottom: 15px !important;

    + .item {
      margin-top: 30px;
    }
  }

  .description {
    color: $neutral-80;
  }
}
