.card {
  background: white;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-size: $text-base;
  border: none;

  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.10);

  transition-duration: .1s;
  transition-property: opacity, box-shadow;
  transition-timing-function: ease-in-out;
  will-change: opacity, box-shadow;

  &:hover {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
  }

  &, a {
    padding: 30px 40px;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  a {
    margin: -30px -40px;
    width: 100%;

    border: none;
    color: inherit;

    &:hover {
      color: inherit;
    }
  }

  header {
    @extend h2;

    margin: 0 0 10px !important;
  }

  .description {
    color: $neutral-60;
  }

  div.icon {
    font-size: rem(36px);
    width: 100px;
    height: 100px;
    border-radius: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 100px;

    margin: 0 0 30px 0;

    display: flex;
    justify-content: center;
    align-items: center;

    color: $primary;
    background-color: $primary-pastel;

    &.green {
      color: $green;
      background-color: $green-pastel;
    }

    &.orange {
      color: $orange;
      background-color: $orange-pastel;
    }
  }

  &.small {
    header {
      @extend h4;

      margin: 0 0 5px !important;
    }

    div.icon {
      font-size: 30px;
      color: $neutral;
      background-color: transparent;
      display: inline-block;
      flex-basis: auto;

      width: 1em;
      height: 1em;
    }
  }

  @include respond-to('tablet') {
    &:not(.small) {
      &, a {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
      }

      div.icon {
        margin: 0 30px 0 0;
      }
    }
  }
}
