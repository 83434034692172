section.features-mini {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 90px;
    padding-bottom: 90px;

    margin: -10px auto;

    .feature {
      margin: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;

      width: 370px;
      max-width: 100%;
    }

    @include respond-to('tablet') {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  .icon {
    color: $neutral;
    width: 60px;
    font-size: 32px;

    flex: 0 1 60px;
  }

  .content {
    color: $neutral;
    padding-top: 15px;
    padding-left: 5px;
  }
}
