section.simple-copy {
  .container {
    max-width: 900px;
    text-align: center;

    padding-top: 100px;
    padding-bottom: 120px;
  }

  .tagline {
    max-width: 640px;
    margin: 30px auto 0;
    font-size: $text-xl;
    line-height: 1.7;
  }

  .description {
    margin: 30px auto 0;
    max-width: 640px;

    font-size: $text-lg;
    text-align: left;
  }
}
