section.testimonials {
  padding-top: 100px;
  padding-bottom: 120px;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;
  }

  h2 {
    margin-bottom: 20px;
  }

  .description {
    font-size: $text-xl;
    font-weight: normal;

    margin: 0 0 90px;

    max-width: 640px;
  }

  .scroll-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .scroll-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: none;
    mask: none;
  }

  .testimonial {
    padding: 40px;
    background-color: $neutral-5;
    border-radius: 20px;
    width: 350px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;

    &:nth-child(3n) {
      background-color: black;
      color: white;
    }

    blockquote {
      margin: 0;
      font-size: 18px;
      line-height: 1.5;
    }

    .author {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-self: flex-end;
      gap: 20px;

      font-family: $font-secondary;
      font-size: $text-sm;
    }

    .avatar {
      width: 50px;
      height: 50px;
    }
  }
}
