.notification {
  position: relative;

  color: $white;
  color: var(--text-color, $white);
  background-color: $primary;
  background-color: var(--background-color, $primary);

  text-align: center;
  padding: 0.6em;

  display: none;
  align-self: flex-start;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;

  z-index: 10000;

  flex: 0 !important;

  &.visible {
    display: flex;
  }

  a, a:hover {
    color: $white;
    opacity: 1;
    border-bottom: 1px dashed currentColor;
  }

  .container {
    color: rgba($white, 0.9);
  }

  .close {
    position: absolute;
    right: 0.6em;
    top: 0.6em;
    cursor: pointer;
  }
}
