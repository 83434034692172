section.integrations {
  .hero {
    padding: rem(60px) 0px rem(100px) 0px;

    h1 {
      margin: rem(40px) 0 rem(20px) 0;
    }

    .description {
      font-size: $text-xl;
      max-width: 640px;
      margin: rem(30px) 0;
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      gap: rem(10px);
      padding: rem(30px) 0;
      max-width: rem(900px);

      .tag {
        font-family: $font-secondary;
        font-size: $text-sm;
        background-color: $neutral-5;
        border-radius: rem(4px);
        padding: rem(4px) rem(8px);
        color: $neutral;
        cursor: pointer;
        text-decoration: none;
        border-bottom: none;

        &[aria-current="page"], &:hover {
          background-color: $neutral-15;
        }
      }
    }
  }

  .integration-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    padding-bottom: rem(120px);

    @include respond-to('tablet') {
      @include grid(2, 30px);
    }

    @include respond-to('desktop') {
      @include grid(4, 30px);
    }

    .integration, .integration-hover {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      gap: rem(20px);
      width: 100%;
      min-width: rem(250px);
      padding: rem(30px);

      border-radius: rem(20px);
      border: 1px solid $neutral-15;

      background-color: white;

      transition: transform, box-shadow, opacity;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;

      .logo-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .logo {
          width: rem(40px);
          height: rem(40px);
        }

        .badge {
          color: #A4A4A4;
          padding: rem(3px) rem(6px);
          border-radius: rem(4px);
          font-size: $text-sm;
          border: 1px solid #A4A4A4;
          background-color: transparent;
          text-transform: none;
        }
      }

      .title {
        font-size: $text-2xl;
        line-height: 1.35;
        letter-spacing: -0.01em;
        color: $neutral;
        margin-bottom: rem(5px);
      }

      .subtitle {
        font-family: $font-secondary;
        font-size: $text-sm;
        color: $neutral-60;
      }

      .description {
        overflow: hidden;
      }

      .links {
        margin-top: rem(20px);
        display: flex;
        flex-direction: column;
        gap: rem(10px);

        a {
          display: inline-block;
          text-decoration: none;
          border-bottom: none;
          padding: rem(4px) rem(8px);
          border-radius: rem(4px);
          background-color: $neutral-5;
          font-family: $font-secondary;
          font-size: $text-sm;
          color: $neutral;
          width: max-content;
          transition: background-color 0.2s ease-in-out;
        }

        a:hover {
          background-color: $neutral-15;
        }
      }
    }

    .integration {
      z-index: 0;
      height: 100%;
    }

    .integration-hover {
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 1;

      opacity: 0;
      box-shadow: none;

      transform: translateY(-50%) scale(1);
      transform-origin: center center;
    }

    .integration-wrapper {
      position: relative;

      &:hover {
        .integration {
          opacity: 0;
        }

        .integration-hover {
          z-index: 100;
          opacity: 1;
          transform: translateY(-50%) scale(1.05);
          box-shadow: 2px 4px 40px 0px rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
}