[data-scroller] {
  .scroll-container {
    mask: linear-gradient(90deg, transparent, white 10%, white 90%, transparent);
    overflow: hidden;
    padding: 0;
  }

  .scroll-group {
    width: max-content;

    display: flex;
    flex-wrap: nowrap;
    gap: 10px;

    animation: scroll 120s forwards linear infinite;

    &:nth-child(3) {
      animation-duration: 80s;
    }

    &[data-scroller-direction=reverse] {
      animation-direction: reverse;
    }

    &[data-scroller-direction=forwards] {
      animation-direction: forwards;
    }
  }
}

@keyframes scroll {
  to {
    transform: translate3d(calc(-50% - 5px), 0, 0);
  }
}
