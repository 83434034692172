section {
  &.light {
    background-color: $white;
  }

  &.toned {
    background-color: $neutral-5;
  }

  &.light-blue {
    background-color: $light-blue;
  }

  &.dark {
    background-color: $neutral;

    h1, h2, h3, h4, h5, h6, header {
      color: $white !important;
    }

    .description {
      color: $gray-on-black !important;

      a {
        color: $white !important;
      }
    }

    .outline {
      background-color: transparent;
      border: solid 1px current;
      color: $white;

      &:hover {
        background-color: $primary;
        border-color: $primary;
      }
    }
  }

  &.bordered {
    border-bottom: solid 1px $neutral-15;
  }
}
