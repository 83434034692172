section.lead-magnet-hero {
  display: flex;
  flex-direction: column;

  .container {
    padding-top: 40px;
    padding-bottom: 120px;
    background: black;
    margin-left: 0;
    flex: 1;
  }

  .image {
    background-size: cover;
    background-position: center center;
    min-height: 500px;

    flex: 1;
  }

  .brand {
    margin-bottom: 30px;

    .logo {
      width: 140px;
      height: 41.51px;
    }

    a {
      color: inherit;
      border: none;
    }

    @include respond-to('desktop') {
      .logo {
        width: 160px;
        height: 47.44px;
      }
    }
  }

  h1 {
    font-size: rem(40px);
  }

  .description {
    max-width: 500px;
    margin: 30px 0 50px;

    font-size: $text-lg;
  }

  .caption {
    opacity: 0.6;
    margin-top: 30px;
    max-width: 530px;

    a {
      color: inherit;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  @include respond-to('desktop') {
    flex-direction: row;

    .container {
      max-width: calc(50vw + 12%);
      min-height: 100vh;
      padding-top: 80px;
      box-sizing: border-box;
    }

    .content {
      max-width: 840px;
    }
  }


  &.black {
    .brand {
      color: #9a9a9a;
    }

    color: #9a9a9a;

    h1 {
      color: white;
    }
  }
}
