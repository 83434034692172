.avatar {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
  display: block;

  img {
    display: block;
    width: 100%;
  }
}
