section.lead-magnet {
  .container {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .image {
      img {
        max-width: 100%;
        display: block;
      }
    }

    .content {
      padding: 50px 6%;
    }

    @include respond-to('tablet') {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      padding-left: 6%;
      padding-right: 6%;

      > img {
        max-width: 50%;
        margin: 0;
      }

      .content {
        padding: 100px 0;
        max-width: 530px;
      }
    }
  }

  form {
    flex-wrap: wrap;

    input {
      flex: 1 0 100%;
    }
  }

  h2 {
    font-size: $text-4xl !important;
  }

  .description {
    margin: 20px 0 40px;
  }

  &.bottom-aligned {
    .container {
      .image {
        padding: 30px 20px 0;
      }

      .content {
        order: 1;
      }

      .image {
        order: 2;
      }

      @include respond-to('tablet') {
        align-items: flex-end;

        .content {
          order: 2;
        }

        .image {
          order: 1;
        }
      }
    }
  }
}
