section.integration-showcase {
  padding-top: 100px;
  padding-bottom: 120px;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
    padding-bottom: 80px;
  }

  .description {
    font-size: $text-xl;
    max-width: 640px;
  }

  .scroll-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .scroll-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .integration-showcase-logo {
    background-color: white;
    border: 1px solid #E9E7EC;
    width: 100px;
    height: 100px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
  }
}
