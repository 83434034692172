section.logos {
  .container {
    text-align: center;

    padding-top: 80px;
    padding-bottom: 80px;
  }

  .logos {
    margin: -20px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .logo {
    margin: 20px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .3em;
    color: $neutral-40;
    font-size: $text-sm;

    img {
      height: 40px;
      margin: 12px 0 0 0;
    }
  }

  h6 {
    margin-bottom: 10px;
  }

  &.fixed-spacing {
    .container {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .logos {
      @include respond-to('desktop') {
        justify-content: space-around;
      }
    }
  }
}
