section.content-grid {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 100px;
    padding-bottom: 120px;
  }

  &.dark .container {
    padding-top: 80px;
    padding-bottom: 90px;
  }

  h2 {
    text-align: center;
    margin-bottom: 80px;
  }

  .contents {
    @include grid(3, 50px);
  }

  &.two-columns {
    .contents {
      @include grid(2, 50px);
    }
  }

  &.four-columns {
    .contents {
      @include grid(4, 50px);
    }
  }

  .item {
    header {
      @extend h4;

      margin-bottom: 10px !important;
    }

    .description {
      color: $neutral-80;
    }
  }
}
