section.updates {
  .container {
    padding-top: 100px;
    padding-bottom: 120px;
  }

  .updates {
    @include grid(3, 80px 60px);

    padding: 60px 0;
  }

  time {
    display: inline-block;
    margin-bottom: 10px;

    color: $neutral-60;
  }
}
