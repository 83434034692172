section.hero {
  .container {
    padding-top: 100px;
    padding-bottom: 120px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  h1 {
    max-width: 900px;
  }

  .description {
    font-size: $text-xl;
    font-weight: normal;

    margin: 30px 0 60px;

    max-width: 640px;

    &:last-child {
      margin-bottom: 0;
    }

    .caption {
      font-size: $text-base;
      margin-top: 45px;
    }
  }

  .actions {
    margin: -10px -5px;

    > * {
      margin: 10px 5px;
    }
  }

  form {
    width: 100%;
    max-width: 735px;
  }

  body.dark & {
    h1 {
      color: $white;
    }
  }
}
