section.feature {
  .container {
    text-align: center;

    padding-top: 100px;
    padding-bottom: 120px;
  }

  img {
    max-width: 100%;
    margin: 40px 0;
  }

  .description {
    max-width: 720px;
    margin: 0 auto;
  }

  .tagline {
    margin: 30px auto;
    font-size: $text-xl;
    max-width: 640px;
  }
}
