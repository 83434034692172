section.image {
  .container {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  img {
    max-width: 100%;
    flex: 0 1 auto;

    @include respond-to('mobile') {
      &.breakpoint-desktop,
      &.breakpoint-tablet {
        display: none;
      }

      &.breakpoint-mobile {
        display: inline-block;
      }
    }

    @include respond-to('tablet') {
      &.breakpoint-desktop,
      &.breakpoint-mobile {
        display: none;
      }

      &.breakpoint-tablet {
        display: inline-block;
      }
    }

    @include respond-to('desktop') {
      &.breakpoint-mobile,
      &.breakpoint-tablet {
        display: none;
      }

      &.breakpoint-desktop {
        display: inline-block;
      }
    }
  }

  &.full-width {
    img {
      width: 100%;
    }
  }
}
