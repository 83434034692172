section.podcast-hero {
  background-color: black;
  color: white;
  margin-top: -96px;

  .container {
    padding-top: 120px;
    padding-bottom: 120px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .content {
    align-items: flex-start;
    text-align: left;
    max-width: 650px;
  }

  .description {
    font-size: $text-xl;
    font-weight: normal;
    color: $gray-on-black;

    margin: 30px 0 60px;
    max-width: 530px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .caption {
    color: rgba(#9a9a9a, 0.6);
    margin-top: 45px;
    max-width: 530px;

    a {
      color: inherit;

      &:hover {
        color: white;
      }
    }
  }

  .meta {
    margin-bottom: 15px;
    color: rgba(#9a9a9a, 0.6);

    a {
      color: inherit;

      &:hover {
        color: white;
      }
    }

    > a, span {
      margin: 0 5px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .player {
    max-width: 530px;
    color: rgba(#9a9a9a, 0.6);

    iframe {
      margin-bottom: 10px;
    }

    a {
      color: inherit;

      &:hover {
        color: white;
      }
    }
  }

  .actions {
    margin: -10px -5px;

    > * {
      margin: 10px 5px;
    }

    .button {
      color: white;
      background-color: #333;

      &:hover {
        background-color: #444;
      }
    }
  }

  .logo {
    img {
      width: 100%;
      max-width: 400px;
    }

    margin: 20px 30px 50px;
  }

  h1 {
    color: white;

    @include respond-to('mobile') {
      font-size: rem(40px);
    }

    @include respond-to('tablet') {
      font-size: rem(50px);
    }
  }

  @include respond-to('desktop') {
    .logo {
      margin: 0;
    }

    .container {
      padding-top: 216px;
      flex-direction: row;
    }
  }
}

.podcast-intro,
.podcast-recap,
.podcast-shownotes,
.podcast-subscribe {
  .container {
    max-width: 650px;
    padding-top: 70px;
    padding-bottom: 100px;
  }

  h2 {
    margin-bottom: 20px;
    font-size: $text-4xl;
  }
}

.podcast-subscribe {
  text-align: center;

  .container {
    padding-top: 100px;
  }

  .podcast-intro + & {
    .container {
      margin-top: -40px;
      padding-top: 0;
    }
  }

  .actions {
    margin: -5px;
  }

  .button {
    background-color: $neutral-15;
    color: $neutral-80;
    box-shadow: none;
    margin: 5px;

    &:hover {
      background-color: #dfdbe4;
    }
  }

  .caption {
    color: $neutral-60;
    margin-top: 20px;

    a {
      color: inherit;

      &:hover {
        color: $primary;
      }
    }
  }
}

.podcast-intro {
  text-align: center;

  .container {
    padding-top: 100px;
  }

  .prose {
    font-size: $text-xl;
  }

  .avatar {
    width: 240px;
    height: 240px;
    margin: 0 auto 40px;
  }
}
