.button {
  display: inline-flex;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  font-size: $text-lg;
  font-family: $font-secondary;
  letter-spacing: -0.01em;
  padding: 1.2rem 2.2rem;
  border-radius: 4px;
  border: none;
  margin: 0;

  transition-property: box-shadow, color, border-color, background-color;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;

  will-change: box-shadow, color, border-color, background-color;

  text-decoration: none;

  box-shadow: 2px 4px 16px rgba(black, 0.1);

  &:hover {
    box-shadow: 2px 4px 16px rgba(black, 0.1), 2px 4px 30px rgba(black, 0.05);
  }

  &.primary {
    background-color: #3793ff;
    border-color: #3793ff;
    color: white;

    &:hover {
      border-color: #4b9eff;
      background-color: #4b9eff;
    }
  }

  &.primary-navigation {
    background-color: #3793ff;
    border-color: #3793ff;
    color: white;
    padding: 12px 22px;
    font-size: 13px;

    .icon {
      margin-right: -5px;
    }

    &:hover {
      border-color: #4b9eff;
      background-color: #4b9eff;
    }
  }

  &.secondary {
    background-color: white;
    border-color: white;
    color: #261744;
  }

  &.tertiary {
    background-color: $neutral-5;

    transition: .2s ease-in-out background-color;
    will-change: background-color;

    padding: 6px 22px;
    border-radius: 20px;
    box-shadow: none;

    font-size: $text-base;
    line-height: 1.7;

    color: $neutral-80;
    border: none;

    .icon {
      margin-right: 5px;
    }

    &.active, &:hover {
      background-color: $neutral-15;
    }

    &.icon-right {
      .icon {
        margin-left: 5px;
        margin-right: 0;
      }
    }
  }

  &.outline {
    border: solid 1px currentColor;

    &:hover {
      border-color: #4b9eff;
      background-color: #4b9eff;
    }
  }
}
