section.articles {
  .container {
    padding-top: 100px;
    padding-bottom: 120px;
  }

  h1 {
    margin: 40px 0 20px;
  }

  .container > .description {
    font-size: $text-xl;
    max-width: 640px;
    margin: 30px 0;
  }

  .articles {
    @include grid(2, 80px 60px);

    padding: 60px 0;

    h3 {
      margin-bottom: $text-base;
      font-size: $text-xl;
    }

    .cover {
      margin-bottom: 30px;
      text-decoration: none;
      border: none;
      display: block;

      img {
        max-width: 100%;
      }
    }
  }

  .placeholder {
    text-align: center;
  }

  &.featured {
    .articles {
      @include grid(3, 80px 60px);

      @include respond-to('tablet') {
        article:first-child {
          display: grid;
          grid-template-columns: inherit;
          grid-gap: inherit;
          grid-column: 1 / span 3;

          h3 {
            font-size: $text-2xl;
          }

          .cover {
            grid-column: 1 / span 2;
            display: block;
            margin: 0;
          }

          .meta:before {
            content: "Featured Article";
            text-transform: uppercase;
            color: $neutral-60;
            font-size: $text-sm;
            font-family: $font-secondary;
            letter-spacing: 3px;
            line-height: 1.75;
            font-weight: normal;
            margin: 20px 0;
            display: inline-block;
          }
        }
      }
    }
  }

  &.latest {
    h2, .description {
      margin-inline: auto;
      text-align: center;
    }

    .articles {
      @include grid(3, 80px 60px);
    }
  }

  &.compact {
    .articles,
    .container {
      padding-top: 0;
    }
  }
}
