figure {
  margin: 2em 0;

  figcaption {
    margin-top: 10px;
    color: $neutral-60;
    font-size: $text-sm;
    text-align: center;
  }
}
