section.episodes {
  .container {
    padding-top: 140px;
    padding-bottom: 120px;
  }

  .episodes {
    @include grid(2, 80px 60px);

    padding: 60px 0;

    img {
      max-width: 100%;
      display: block;
      margin-bottom: 30px;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 30px;
  }

  .description {
    text-align: center;
    max-width: 640px;
    margin: 0 auto 20px;
    font-size: $text-xl;
  }

  .pagination {
    justify-content: center;
  }
}
