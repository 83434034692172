.menu {
  background: $white;
  max-width: 100%;
  box-sizing: border-box;
  color: $neutral;

  position: absolute;
  top: 0;
  left: 0;

  width: max-content;

  z-index: 5;

  padding: 20px;

  pointer-events: none;
  opacity: 0;

  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;

  .arrow {
    position: absolute;
    margin: 0 -10px;
    z-index: 10;

    transition-property: transform;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;

    transform: translate(0, 0);
  }

  &.visible {
    pointer-events: auto;
    opacity: 1;
  }

  [data-expanded=true] & .arrow {
    transform: translate(0, -9px);
  }

  @include respond-to('tablet') {
    width: 100%;
    box-shadow: 0px 32px 32px rgba(0, 0, 0, 0.1);
    transform: translate(0, 65px);
  }

  @include respond-to('mobile') {
    width: 100%;
    box-shadow: 0px 32px 32px rgba(0, 0, 0, 0.1);
    transform: translate(0, 65px);
  }

  @include respond-to('desktop') {
    border-radius: 5px;
    box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.1);
    transform: translate(0, 0);
    width: fit-content

  }
}
