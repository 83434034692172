section.testimonial {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    padding-top: 100px;
    padding-bottom: 100px;
  }

  blockquote {
    line-height: 1.3;
    max-width: 800px;
    margin: 40px 0 0 0;
    letter-spacing: -0.01em;

    color: $neutral;
    font-size: $text-3xl;
    font-weight: 300;

    footer {
      color: $neutral;
      font-size: $text-base;
      font-family: $font-secondary;
      letter-spacing: -0.01em;
      margin-top: 30px;

      &:before {
        content: '– ';
      }
    }
  }
}
